<ion-content padding no-bounce text-center>
  <app-header
    [iconName]="'menu'"
    [nav]="nav"
    [title]="null"
    [menuToggleRight]="true"
    [showErrorMessageNetwork]="true"
    [showErrorMessageHealth]="true"
    [allowHeartDisplay]="true">
  </app-header>
  <div class="error-network" *ngIf="!isInternetAccessAvailable">
    <span class="error-network-container">
        <i class="icon-warning"></i>
        <span>{{"NoAccessInternet" | translate}}</span>
    </span>
  </div>
  <!-- Refresher -->
  <ion-refresher (ionRefresh)="refreshReleaseHistory($event)" slot="fixed">
  </ion-refresher>
  <div class="top-content">
  <!-- Greetings -->
  <div class="greetings-container">
    <div class="greetings" *ngIf="user && user.name">
      <div *ngIf="user.embedded.authentication_details && user.embedded.authentication_details.source">
        <span ng-class="{'hide-greeting': path !== '/print'}">{{'SignedInWithAs'| translate | stringular: user.name : (signInMethods[user.embedded.authentication_details.source] | translate)}}</span>
      </div>
      <div *ngIf="!user.embedded.authentication_details || !user.embedded.authentication_details.source">
        <span ng-class="{'hide-greeting': path !== '/print'}">{{'SignedInAs'| translate | stringular: user.name}}</span>
      </div>
    </div>
    <div class="greetings" *ngIf="tenant && tenant.tenantData.hostingDomain">
      <span ng-class="{'hide-greeting': path !== '/print'}">{{tenant.tenantData.hostingDomain}}</span>
    </div>
  </div>
  <!-- Buttons -->
  <div class="action-button-container" *ngIf="dataIsLoaded">
    <div class="release-history-action-buttons">
      <ion-title class="release-history__title" *ngIf="">{{'ReleaseHistory' | translate}}</ion-title>
      <button class="size120 page-action-button refresh-button"
        icon-only
        large
        item-end
        (click)="refreshReleaseHistory()">
        <i class="icon-refresh"></i>
      </button>
    </div>
  </div>
</div>
  <!-- Release -->
  <div class="print__content pt0 pr20 pb100 pl20">
    <!-- No releases -->
    <div  *ngIf="!printJobReleases && dataIsLoaded">
      <h2 class="empty-state__heading ng-binding">
        {{'NoRecentReleases' | translate}}
      </h2>
    </div>
    <div class="list list--boxed list--spaced" *ngFor="let release of printJobReleases | orderBy : 'submitTime'">
      <div class="list__heading" *ngIf="release.printerQueue">
        <div class="relative list__heading__content"  *ngIf="release.printerQueue.printer">
          <span class="list__heading__title"
          (click)="presentInfoModal($event, release.printerQueue.printer, 'printerInfo')">
            <div class="print__printer-id sign-id" tappable>
              <div>{{release.printerQueue.printer.signId}}</div>
              <div>
                <span class="printer-status"
                [ngClass]="{
                  'printer-status--online': release.printerQueue.printer.embedded.status.state === 'READY',
                  'printer-status--warning': release.printerQueue.printer.embedded.status.state === 'WARNING',
                  'printer-status--offline': release.printerQueue.printer.embedded.status.state === 'ERROR',
                  'printer-status--undefined': release.printerQueue.printer.embedded.status.state === 'UNAVAILABLE'}">
                </span>
                <span *ngIf="!release.printerQueue.printer.embedded.status.pollStatus">
                  <span class="status-text-placement" *ngIf="release.printerQueue.printer.embedded.status.state !== 'ERROR'">
                    {{"ONLINE" | translate}}
                  </span>
                  <span class="status-text-placement" *ngIf="release.printerQueue.printer.embedded.status.state === 'ERROR'">
                    {{"OFFLINE" | translate}}
                  </span>
                </span>
                <span class="" *ngIf="release.printerQueue.printer.embedded.status.pollStatus">
                    <span>
                      <span class="status-text-placement" *ngIf="release.printerQueue.printer.embedded.status.state === 'READY'">{{'Ready' | translate}}</span>
                      <span class="status-text-placement" *ngIf="release.printerQueue.printer.embedded.status.state === 'WARNING'">{{'WARNING' | translate}}</span>
                      <span class="status-text-placement" *ngIf="release.printerQueue.printer.embedded.status.state === 'ERROR'">{{'ERROR' | translate}}</span>
                      <span class="status-text-placement" *ngIf="release.printerQueue.printer.embedded.status.state === 'UNAVAILABLE'">{{'OFFLINE' | translate}}</span>
                    </span>
                  </span>
              </div>
            </div>
            <div class="print__printer-id" tappable>
              <div class="print__printer-id__text">{{release.printerQueue.printer.name}}</div>
            </div>
          </span>

          <div class="list__heading__printer-info">
            <div class="print__printer-description" *ngIf="release.printerQueue.queue">{{release.printerQueue.queue.name}}</div>
            <div class="print__printer-description" *ngIf="release.printerQueue.printer.location">{{release.printerQueue.printer.location}}</div>
          </div>
        </div>
        <div class="list__heading__printer-info__created" am-time-ago="release.modified">
          {{release.modified | amLocale: currentLanguage | amTimeAgo}}
        </div>
      </div>

      <!-- PrintJob -->
      <div class="list-content">
        <div class="list-row list__item list__item--clickable" *ngFor="let releaseJobResource of release.releaseJobs">
          <button class="list-cell actionbutton" *ngIf="releaseJobResource.releaseJob">
            <span class="list__item-title">{{releaseJobResource.releaseJob.jobName}}</span>
            <div class="list__item-description">
              <div>
                {{releaseJobResource.releaseJob.pageCount}}
                <span *ngIf="releaseJobResource.releaseJob.pageCount === 1">{{"Page" | translate}}</span>
                <span *ngIf="releaseJobResource.releaseJob.pageCount > 1">{{"Pages" | translate}}</span>
                <span>&nbsp;&nbsp;</span>
                <div>
                  <span am-time-ago="releaseJobResource.releaseJob.submitTime">{{releaseJobResource.releaseJob.submitTime | amLocale: currentLanguage | amTimeAgo}}</span>
                </div>
              </div>

              <div>
                <span class="loader loader--sm loader--compact col-xs-4 loader--spaceless"
                    *ngIf="releaseJobResource.releaseJobStatus.inProgress">
                  <span></span>
                </span>
                <span [ngClass]="{'col-xs-8': releaseJobResource.releaseJobStatus.inProgress}">
                    <span *ngIf="!releaseJobResource.releaseJobStatus.isFailed && releaseJobResource.releaseJobStatus.isFinished">
                      <i class="icon-check text-success"></i>
                    </span>
                    <span *ngIf="releaseJobResource.releaseJobStatus.isFailed">
                      <i class="icon-close text-danger"></i>
                    </span>
                    {{releaseJobResource.releaseJobStatus.jobState | translate}}&nbsp;
                </span>
              </div>

              <div *ngIf="releaseJobResource.releaseJob.options.resourceErrors.length !== 0">
                <div class="resource-error-text" *ngFor="let error of releaseJobResource.releaseJob.options.resourceErrors">
                  <i class="icon-close text-danger"></i>
                  <span>{{error.type | translate}}</span>
                </div>
              </div>
            </div>
          </button>

          <button class="list-cell"
            (click)="presentInfoModal($event, releaseJobResource, 'showPrintJobInfo')"
            *ngIf="releaseJobResource.releaseJob">
            <i class="icon-overflow-vert"></i>
          </button>

          <button class="list-cell no-print-job" *ngIf="!releaseJobResource.releaseJob">
            <div class="list__item-description">{{releaseJobResource.jobId}}</div>
            <div class="list__item-description">{{releaseJobResource.releaseJobStatus.jobState | translate}}</div>
            <span class="list__item-title">{{"DELETED" | translate}}</span>
          </button>
        </div>
      </div> <!-- /PrintJob -->
    </div>
  </div> <!-- /Release -->
</ion-content>
<!-- Pagination -->
<div class="pagination-container printer-list" *ngIf="pagingObject.totalPages > 0">
  <button class="btn btn-sm prev" (click)="currentPage > 0 ? getNextPage(true) : false" [disabled]="pagingObject.number === 0">
    <i class="icon-left-open"></i>
  </button>
  <span class="paging-numerics">{{pagingObject.number + 1}}/{{pagingObject.totalPages}}</span>
  <button class="btn btn-sm next" [disabled]="currentPage + 1 >= pagingObject.totalPages" (click)="getNextPage()">
    <i class="icon-right-open"></i>
  </button>
</div> <!-- Pagination -->