<ion-content no-bounce>
    <app-header
    [iconName]="'menu'"
    [nav]="nav"
    [title]="null"
    [menuToggleRight]="true"
    [showErrorMessageNetwork]="true"
    [showErrorMessageHealth]="true"
    [allowHeartDisplay]="true">
  </app-header>
    <div class="error-network" *ngIf="!isInternetAccessAvailable">
        <span class="error-network-container">
          <i class="icon-warning"></i>
          <span>{{"NoAccessInternet" | translate}}</span>
        </span>
    </div>

    <div class="printer-list-actions">
        <ion-searchbar
        [ngClass]="{'web-app': !isHybrid}"
        placeholder="{{'Search' | translate}}"
        [(ngModel)]="myInput"
        showClearButton="focus"
        clear-icon
        (change)="onSearch($event, myInput)"
        (ionClear)="onSearch($event, null)">
        </ion-searchbar>
        <div class="selected-networks-container">
            <div class="selected-network" *ngFor="let filter of storedNetworkFilters">
                <div class="selected-network__content" *ngIf="filter">
                    {{filter['name']}} <button class="dismissSelectedFilter" (click)="dismissSelectedFilter(filter)"><span>x</span></button>
                </div>
            </div>
        </div>
      <!-- <button class="filter-button aleft" ion-button item-end
      (click)="refreshUser()">
          <span>{{"RefreshUser" | translate}}</span>
          <span class="filter-icon">
              <i class="icon-refresh"></i>
          </span>
      </button> -->
      <ion-button fill="outline" class="filter-button" [ngClass]="{'android': platform.is('android'), 'web-app': !isHybrid}"
      (click)="presentPopover($event, 'filterPrinterList')">
          <span>{{"Filters" | translate}}</span>
          <span class="filter-icon">
              <i class="icon-filter-useful"></i>
          </span>
      </ion-button>
  </div>

  <div class="text-center mt40 pl20 pr20 height100"
  *ngIf="!loadingPage && printerQueuesList.length < 1">
     <div>{{"NoPrinterMatchDocuments" | translate}}</div>
  </div>

  <ion-list>
      <div class="list list--boxed list--spaced">
          <ion-item class="queue-list-item" *ngFor="let printQueue of printerQueuesList">
              <div class="printer-queue-container" [ngClass]="{'last-used-printer' : printQueue.isLastUsedQueue}">
                  <button class="select-printer-clickzone printer-queue" (click)="itemClick(printQueue, 'selectPrintQueue')">
                      <div class="printer-id queues-list printer-id--online pull-left"
                      [ngClass]="{'adjust-signId' : printQueue.printerDetails.duplexSupport || printQueue.printerDetails.colorSupport}">
                          <div>{{printQueue.signId}}</div>
                          <div class="printer-features">
                            <div *ngIf="printQueue.printerDetails.duplexSupport || printQueue.printerDetails.colorSupport">
                                <img class="printer-feature__icon printer-feature--color"
                                title="{{'Color print' | translate}}"
                                *ngIf="printQueue.printerDetails.colorSupport"
                                src="https://assets.printix.net/img/v2/icons/printer-features/cmyk.svg"
                                alt="CMYK icon">

                                <img class="printer-feature__icon printer-feature--duplex"
                                title="{{'Two-sided print' | translate}}"
                                *ngIf="printQueue.printerDetails.duplexSupport"
                                src="https://assets.printix.net/img/v2/icons/printer-features/duplex.svg"
                                alt="Duplex icon">
                            </div>
                          </div>
                      </div>
                    </button>
                    <button class="select-printer-clickzone printer-queue text" (click)="itemClick(printQueue, 'selectPrintQueue')">
                        <div class="queue-text pull-left">
                            <div class="queue-name">{{printQueue.name}} </div>
                            <div class="printer-location" *ngIf="printQueue.printerDetails.location">{{printQueue.printerDetails.location}}</div>
                        </div>
                    </button>
                  <button class="favorite-printer-clickzone" (click)="toggleFavoritePrinterQueue(printQueue)">
                      <span class="favorite-printer-icon">
                        <i class="favorite-color" [ngClass]="{
                            'icon-favorite': !printQueue.isFavorite,
                            'icon-favorite-solid': printQueue.isFavorite,
                            'android': platform.is('android')}">
                        </i>
                      </span>
                  </button>
                  <button class="favorite-printer-clickzone printer-info" tappable (click)="itemClick(printQueue, 'printerInfo')" >
                      <span class="favorite-printer-icon">
                          <i class="favorite-color info-icon">i</i>
                      </span>
                  </button>
              </div>
          </ion-item>
      </div>
  </ion-list>
</ion-content>
<div class="pagination-container printer-list" *ngIf="pagingObject.totalPages > 0">
  <button class="btn btn-sm prev" (click)="currentPage > 0 ? getNextPage(true) : false" [disabled]="pagingObject.number === 0">
      <i class="icon-left-open"></i>
  </button>
  <span class="paging-numerics">{{pagingObject.number + 1}}/{{pagingObject.totalPages}}</span>
  <button class="btn btn-sm next" [disabled]="currentPage + 1 >= pagingObject.totalPages" (click)="getNextPage()">
      <i class="icon-right-open"></i>
  </button>
</div>

