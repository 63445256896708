import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Observer, Subscriber } from 'rxjs';
import { delay, finalize, retryWhen, take, tap } from 'rxjs/operators';
import { ErrorService } from '../error/error.service';
import { PagingService } from '../paging/paging.service';
import { IPrintJob } from '../print-job/models/print-job.model';
import { TenantService } from '../tenant/tenant.service';
import { IUser } from '../user/models/user.model';
import { UserService } from '../user/user.service';
import { IPrinterEmbedded } from './models/printer-embedded-model';
import { IPrinter } from './models/printer.model';
import { IQueue } from './models/queue.model';
import * as _ from 'lodash';
import { Logger, LoggingService } from 'ionic-logging-service';

@Injectable({
  providedIn: 'root'
})
export class PrinterService {

  private patchObserver: Observer<IPrinter> = null;
  private user: IUser = null;
  private printerListPageObject: any;
  private printersFromQueuesRequest: any;
  private PRINTER: string = 'PRINTER';
  private httpErrorResponse: HttpErrorResponse;
  private logger: Logger;

  constructor(
    private errorService: ErrorService,
    private httpClient: HttpClient,
    private pagingService: PagingService,
    private loggingService: LoggingService,
    private tenantService: TenantService,
    private userService: UserService
    ) {
      this.logger = loggingService.getLogger("[PrinterService]");
      const methodName = "ctor";
      this.logger.entry(methodName);

      this.user = _.cloneDeep(this.userService.user);
  }

  private changesListenerStart(printer): void {
    // console.log('## change START');
    // this.patchObserver = jsonPatch.observe(printer, null);
  }

  private changesListenerStop(printer): void {
    // console.log('## change STOP');
    // if (this.patchObserver && printer) {
    // jsonPatch.unobserve<IPrinter>(printer, this.patchObserver);
    // }
  }

  private deserializeLinkObjects(printer: IPrinter, links: any): void {
    if (links) {
      if (links['self'] && links['self'].href) {
        printer.links.self = links['self'].href;
      }

      if (links['px:registerNfcTag'] && links['px:registerNfcTag'].href) {
        printer.links.registerNfcTag = links['px:registerNfcTag'].href;
      }
    }
  }

  private deserializeEmbeddedObjects(printer: IPrinter, embedded: any): void {
    if (embedded) {
      let embeddedParameters: IPrinterEmbedded = {
        printerModel: embedded['printerModel'] ? embedded['printerModel'] : null,
        queues: embedded['queues'] ? embedded['queues'] : [],
        status: embedded['status'] ? {
          errors: embedded['status']['errors'] ? embedded['status']['errors'] : [],
          lastPollTime: embedded['status']['lastPollTime'] ? embedded['status']['lastPollTime'] : null,
          lastSuccessfullPoll: embedded['status']['lastSuccessfullPoll'] ? embedded['status']['lastSuccessfullPoll'] : null,
          lastUsagePollTime: embedded['status']['lastUsagePollTime'] ? embedded['status']['lastUsagePollTime'] : null,
          links: embedded['status']['_links'] ? {
            self: embedded['status']['_links']['self'] ? embedded['status']['_links']['self']['href'] : null,
            monitorWs: embedded['status']['_links']['px:monitorWs'] ? embedded['status']['_links']['px:monitorWs']['href'] : null,
          } : null,
          pollStatus: embedded['status']['pollStatus'] ? embedded['status']['pollStatus'] : null,
          printerReportedState: embedded['status']['printerReportedState'] ? embedded['status']['printerReportedState'] : null,
          state: embedded['status']['state'] ? embedded['status']['state'] : null,
          tonerLevels: embedded['status']['tonerLevels'] ? embedded['status']['tonerLevels'] : null,
          warnings: embedded['status']['warnings'] ? embedded['status']['warnings'] : [],
        } : null
      };
      printer.embedded = embeddedParameters;
    }
  }

  public deserializePrinter(input: any, skipObservers: boolean): IPrinter {
    this.changesListenerStop(input);
    let printer: IPrinter = {
      links: {
        self: null,
        registerNfcTag: null
      },
      colorSupport: input.colorSupport ? input.colorSupport : false,
      created: input.created ? input.created : null,
      duplexSupport: input.duplexSupport ? input.duplexSupport : false,
      embedded: input._embedded ? input._embedded : null,
      geoLocation: input.geoLocation ? input.geoLocation : null,
      ipAddress: input.ipAddress ? input.ipAddress : null,
      location: input.location ? input.location : null,
      macAddress: input.macAddress ? input.macAddress : null,
      name: input.name ? input.name : null,
      pdls: input.pdls ? input.pdls : null,
      serialNumber: input.serialNumber ? input.serialNumber : null,
      signId: input.signId ? input.signId : null,
      type: input.type ? input.type : null,
      version: input.version ? input.version : 0,
      network: this.deserializeNetwork(input) ,
      queues: input._embedded ? this.formatQueuesArray(input._embedded) : [],
      options: {
        isAllPrintJobsSelected: false,
        allowToPrint: true,
        isSelectedToPrintOnIt: false,
        isFavorite: false
      }
    };
    if (input._links) {this.deserializeLinkObjects(printer, input._links);}
    if (input._embedded) {this.deserializeEmbeddedObjects(printer, input._embedded);}
    if (!skipObservers) {
      this.changesListenerStart(printer);
    }
    return printer;
  }

  private deserializeNetwork(input): string {
    if (input.type !== 'FREEDOM' && input._links && input._links['px:network'] && input._links['px:network'].href) {
      return input._links['px:network'].href;
    } else {
      return null;
    }
  }

  public formatQueuesArray (embedded): Array<IQueue> {

    let queusArray: Array<IQueue> = [];
    if (embedded.queues) {
      for (let i = 0; i < embedded.queues.length; i++) {
        let queue: IQueue = {
          links: {
            self: embedded.queues[i]._links.self.href ? embedded.queues[i]._links.self.href : null,
            printer: embedded.queues[i]._links['px:printer'].href ? embedded.queues[i]._links['px:printer'].href : null
          },
          active: embedded.queues[i].active ? embedded.queues[i].active : false,
          alwaysReprint: embedded.queues[i].alwaysReprint ? embedded.queues[i].alwaysReprint : false,
          autoInstall: embedded.queues[i].autoInstall ? embedded.queues[i].autoInstall : false,
          autoUpdate: embedded.queues[i].autoUpdate ? embedded.queues[i].autoUpdate : false,
          defaultDuplex: embedded.queues[i].defaultDuplex ? embedded.queues[i].defaultDuplex : false,
          defaultMono: embedded.queues[i].defaultMono ? embedded.queues[i].defaultMono : false,
          errors: [],
          global: embedded.queues[i].global ? embedded.queues[i].global : false,
          name: embedded.queues[i].name ? embedded.queues[i].name : null,
          signId: embedded.queues[i].signId ? embedded.queues[i].signId : null,
          groups: embedded.queues[i] ? this.formateGroupsArray(embedded.queues[i]) : [],
          isFavorite: embedded.queues[i].isFavorite ? embedded.queues[i].isFavorite : false,
          isDisabled: embedded.queues[i].isDisabled ? embedded.queues[i].isDisabled : false,
          isLastUsedQueue: embedded.queues[i].isLastUsedQueue ? embedded.queues[i].isLastUsedQueue : false,
          printerDetails: {
            location: null,
            colorSupport: false,
            duplexSupport: false
          },
        };
        queusArray.push(queue);
      }
    }
    return queusArray;
  }

  public formateGroupsArray (queue): Array<string> {
    let groupsArray: Array<string> = [];
    if (queue._links && queue._links['px:group']) {
      if (Array.isArray(queue._links['px:group'])) {
        for (let i = 0; i < queue._links['px:group'].length; i++) {
          groupsArray.push(queue._links['px:group'][i].href);
        }
      } else {
        groupsArray.push(queue._links['px:group'].href);
      }
    }
    return groupsArray;
  }

  private deserializePrinterList(input: any): Array<IPrinter> {
    let printerList: Array<IPrinter> = [];
    if (input._embedded && input._embedded['px:printerResources']) {
      for (let printer of input._embedded['px:printerResources']) {
        printerList.push(this.deserializePrinter(printer, true));
      }
    }
    printerList.push(this.printerListPageObject);
    return printerList;
  }

  public getPrinterList(parameters: any, urlString: string): Observable<Array<IPrinter>> {
    this.logger.info('getPrinterList()');
    let printerUrl = this.tenantService.tenant.links.printers + '?' + urlString;
    return new Observable((observer) => {
      this.httpClient.post<any>(printerUrl, parameters)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('getPrinterList() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'POST', '[PrinterService] getPrinterList()');
          }
          observer.error();
          observer.complete();
        })
      )))
      .subscribe((response: any) => {
        this.printerListPageObject = {
          page: response.page
        };
        observer.next(this.deserializePrinterList(response));
        observer.complete();
      });
    });
  }

  private deserializePrintQueue(input: any): IQueue {
    let queue: IQueue = {
      links: {
        self: input._links.self.href ? input._links.self.href : null,
        printer: input._links['px:printer'].href ? input._links['px:printer'].href : null
      },
      active: input.active ? input.active : false,
      alwaysReprint: input.alwaysReprint ? input.alwaysReprint : false,
      autoInstall: input.autoInstall ? input.autoInstall : false,
      autoUpdate: input.autoUpdate ? input.autoUpdate : false,
      defaultDuplex: input.defaultDuplex ? input.defaultDuplex : false,
      defaultMono: input.defaultMono ? input.defaultMono : false,
      errors: [],
      global: input.global ? input.global : false,
      name: input.name ? input.name : null,
      signId: input.signId ? input.signId : null,
      isFavorite: input.isFavorite ? input.isFavorite : false,
      isDisabled: input.isDisabled ? input.isDisabled : false,
      isLastUsedQueue: input.isLastUsedQueue ? input.isLastUsedQueue : false,
      printerDetails: {
        location: input.location ? input.location : null,
        colorSupport: input.color ? input.color : false,
        duplexSupport: input.duplex ? input.duplex : false
      },
    };
    return queue;
  }

  private deserializePrintQueuesList(input: any): Array<IQueue> {
    let printQueuesList: Array<IQueue> = [];
    if (input._embedded && input._embedded['px:printerQueueResources']) {
      for (let queue of input._embedded['px:printerQueueResources']) {
        printQueuesList.push(this.deserializePrintQueue(queue));
      }
    }
    printQueuesList.push(this.printersFromQueuesRequest);
    printQueuesList.push(this.printerListPageObject);

    return printQueuesList;
  }

  public getPrinterQueuesList(parameters: any): Observable<Array<IQueue>> {
    this.logger.info('getPrinterQueuesList()');
    let queuesUrl = this.tenantService.tenant.links.queues;
    return new Observable((observer) => {
      this.httpClient.post<any>(queuesUrl, parameters)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('getPrinterQueuesList() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'POST', '[PrinterService] getPrinterQueuesList()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response: any) => {
        this.printerListPageObject = {
          page: response.page
        };
        let printers: Array<IPrinter> = this.deserializePrinterList(response);
        printers.pop()['page'];
        this.printersFromQueuesRequest = {
          printers: printers
        };
        observer.next(this.deserializePrintQueuesList(response));
        observer.complete();
      });
    });
  }

  public resetPrintersAllowToPrintProperty(printerList) {
    printerList.forEach(p => {
      p.options.allowToPrint = true;
      p.options.allPrintJobsSelected = false;
    });
  }

  public refreshPrinterStatus(printer): Observable<any> {
    this.logger.info('refreshPrinterStatus()');
    let statusMethod = 'STATUS';
    return new Observable((observer) => {
      this.httpClient.get<any>(printer.embedded.status.links.self + "?pollDeviceFirst=true&method=" + statusMethod)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(1),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          this.logger.info('refreshPrinterStatus() httpErrorResponse: ' + this.httpErrorResponse);
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('refreshPrinterStatus() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            if (this.httpErrorResponse.status === 503) {
              this.logger.info('refreshPrinterStatus() httpErrorResponse === 503');
            } else {
              this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'GET', '[PrinterService] refreshPrinterStatus()');
            }
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response: any) => {
        printer.embedded.status = {
          errors: response['errors'] ? response['errors'] : [],
          lastPollTime: response['lastPollTime'] ? response['lastPollTime'] : null,
          lastSuccessfullPoll: response['lastSuccessfullPoll'] ? response['lastSuccessfullPoll'] : null,
          lastUsagePollTime: response['lastUsagePollTime'] ? response['lastUsagePollTime'] : null,
          links: response['_links'] ? {
            self: response['_links']['self'] ? response['_links']['self']['href'] : null,
            monitorWs: response['_links']['px:monitorWs'] ? response['_links']['px:monitorWs']['href'] : null,
          } : null,
          pollStatus: response['pollStatus'] ? response['pollStatus'] : null,
          printerReportedState: response['printerReportedState'] ? response['printerReportedState'] : null,
          state: response['state'] ? response['state'] : null,
          tonerLevels: response['tonerLevels'] ? response['tonerLevels'] : null,
          warnings: response['warnings'] ? response['warnings'] : [],
        };
        observer.next(printer);
        observer.complete();
      });
    });
  }

  public getSinglePrinter(PrinterUrl: string, printerUrlString: string): Observable<Array<IPrinter>> {
    this.logger.info('getSinglePrinter()');
    return new Observable((observer) => {
      if (PrinterUrl) { // Is there a last used printer
        let selectedPrinterParameters: any = this.pagingService.getPrinterParameters();
        selectedPrinterParameters.printers = [];
        selectedPrinterParameters.printers.push(PrinterUrl);
        this.getPrinterList(selectedPrinterParameters, printerUrlString)
        .subscribe((selectedPrinter: Array<IPrinter>) => {
          observer.next(selectedPrinter);
          observer.complete();
        }, (error) => {
          observer.error();
          observer.complete();
        });
      } else { // no last used printer
        observer.next([]);
        observer.complete();
      }
    });
  }

  public getSingleQueue(queueUrl: string): Observable<IQueue> {
    this.logger.info('getSingleQueue()');
    return new Observable((observer) => {
      this.httpClient.get<IQueue>(queueUrl)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('getSingleQueue() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'GET', '[PrinterService] getSingleQueue()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((queue) => {
        observer.next(this.deserializePrintQueue(queue));
        observer.complete();
      });
    });
  }

  public getPrintersWithPrintJobs(printJobs: Array<IPrintJob>, printerUrlString: string): Observable<Array<IPrinter>> {
    this.logger.info('getPrintersWithPrintJobs()');
    let printers: Array<IPrinter> = [];
    return new Observable((observer) => {
      if (printJobs.length !== 0) {
        let printerParameters = this.pagingService.getPrinterParameters();

        printerParameters.printers = [];
        printerParameters.signIds = [];

        printJobs.forEach(pj => { // get the printers that has printJobs
          if (printerParameters.printers.indexOf(pj.links.printer) === -1) {
            printerParameters.printers.push(pj.links.printer);
          }
        });

        this.getPrinterList(printerParameters, printerUrlString)
        .subscribe((printerList: Array<IPrinter>) => {
          let pagingObjectIndex = printerList.length -1;
          printerList.splice(pagingObjectIndex, 1); // remove the paging object from the list


          observer.next(printerList);
          observer.complete();
        });
      } else {
        observer.next(printers);
        observer.complete();
      }
    });
  }
}